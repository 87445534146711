var baseMediaPath = "";
var debugVerbose = true;
var useLocalStorage = false;

console.log("baseMediaPath: " + baseMediaPath);

const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const mymap = L.map("main_map", { zoomControl: false }).setView(
  [-24.801233, 132.94551],
  5
);
mymap.fitBounds([
  [-10, 112],
  [-44, 154],
]);

const tiles = L.tileLayer(tileUrl, { attribution });
tiles.addTo(mymap);

var minTemp = 0.0;
var maxTemp = 40.0;
var minHumidity = -45.0;
var maxHumidity = -10.0;
var minWindSpeed = 110.0;
var maxWindSpeed = 155.0;
var earliestTime = 0;
var latestTime = 1440;
var earliestTimeOfYear = 0;
var latestTimeOfYear = 365;
var earliestDate = 0;
var latestDate = Date.now() / 1000;
var useFilters = true;
var includeXenoCanto = true;
var includeMacauley = true;
var include_xeno_canto_string = "true";
var include_macauley_string = "true";

var markers = L.markerClusterGroup({
  spiderfyDistanceMultiplier: 2.0, // set the radius to 1 the default value is 1.5
  maxClusterRadius: 55,
});

var windowHasLoaded = false;
var timeoutId;
var filePath = "";
var file_suffix = "";
let controller = null; // AbortController to cancel the previous request if needed

document.addEventListener("DOMContentLoaded", function (event) {
  windowHasLoaded = true;
  if (useLocalStorage) {
    readFromlocalStorage();
  }
});

document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
  console.log("Right-click prevented");
});

const popup = L.popup();

// Add an event listener to the popup
popup.addEventListener("contextmenu", (event) => {
  event.preventDefault();
  console.log("Right-click prevented inside popup");
});

const mapFilters = document.getElementById("mapFilters");
mapFilters.style.display = "none";

const settingsButton = document.getElementById("settingsButton");
settingsButton.addEventListener("click", showHideDiv);

const sideNavOpenButton = document.getElementById("sideNavOpenButton");
sideNavOpenButton.addEventListener("click", openNav);

const sideNavCloseButton = document.getElementById("sideNavCloseButton");
sideNavCloseButton.addEventListener("click", closeNav);

function openNav() {
  console.log("open nav");
  document.getElementById("mySidenav").style.width = "250px";
  document.getElementById("main_map").style.marginLeft = "250px";
}

function closeNav() {
  console.log("close nav");
  document.getElementById("mySidenav").style.width = "0";
  document.getElementById("main_map").style.marginLeft = "0";
}

function showHideDiv() {
  if (debugVerbose) {
    console.log("show div " + mapFilters.style.display);
  }
  if (mapFilters.style.display === "none") {
    mapFilters.style.display = "inline-block";
    disableMap();
  } else {
    mapFilters.style.display = "none";
    enableMap();
  }
}

function disableMap() {
  mymap.dragging.disable();
  mymap.touchZoom.disable();
  mymap.doubleClickZoom.disable();
  mymap.scrollWheelZoom.disable();
  mymap.boxZoom.disable();
  mymap.keyboard.disable();
  if (mymap.tap) mymap.tap.disable();
  document.getElementById("main_map").style.cursor = "default";
}
function enableMap() {
  mymap.dragging.enable();
  mymap.touchZoom.enable();
  mymap.doubleClickZoom.enable();
  mymap.scrollWheelZoom.enable();
  mymap.boxZoom.enable();
  mymap.keyboard.enable();
  if (mymap.tap) mymap.tap.enable();
  document.getElementById("main_map").style.cursor = "grab";
}

var bounds = [
  [minHumidity, minWindSpeed],
  [maxHumidity, maxWindSpeed],
];
//This is the main function for getting data.
async function getData() {
  if (windowHasLoaded) {
    // Log the current unix time stamp
    if (debugVerbose) {
      console.log("Start request: " + Date.now() / 1000);
    }

    if (controller) {
       controller.abort(); // Cancel the previous request if any
    }

    controller = new AbortController();
    const signal = controller.signal;
    var url;
    if (useFilters) {
      url =
        "/mapRequest?&minHumidity=" +
        minHumidity +
        "&maxHumidity=" +
        maxHumidity +
        "&minWindSpeed=" +
        minWindSpeed +
        "&maxWindSpeed=" +
        maxWindSpeed +
        "&minTemp=" +
        String(minTemp).slice(0, -3) +
        "&maxTemp=" +
        String(maxTemp).slice(0, -3) +
        "&earliestDate=" +
        earliestDate / 1000 +
        "&latestDate=" +
        latestDate +
        "&filters=true" +
        "&earliestTime=" +
        earliestTime +
        "&latestTime=" +
        latestTime +
        "&earliestTimeOfYear=" +
        earliestTimeOfYear +
        "&latestTimeOfYear=" +
        latestTimeOfYear +
        "&includeXenoCanto=" +
        include_xeno_canto_string +
        "&includeMacauley=" +
        include_macauley_string;
      console.log(url);
    } else {
      url =
        "/mapRequest?&minHumidity=-0&maxHumidity=100&minWindSpeed=0&maxWindSpeed=100&minTemp=-20&maxTemp=50&earliestDate=315493200&latestDate=" +
        Date.now() / 1000 +
        "&filters=false&earliestTime=0&latestTime=1440&earliestTimeOfYear=0&latestTimeOfYear=365&includeXenoCanto=" +
        include_xeno_canto_string +
        "&includeMacauley=" +
        include_macauley_string;
      console.log(url);
    }
    try {
      const response = await fetch(url, { signal });

      if (!response.body) {
        console.error("ReadableStream not yet supported in this browser.");
        return;
      }
      mymap.removeLayer(markers);
      markers.clearLayers();

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      try {
        await processResult(reader, decoder, buffer);
      } catch (error) {
        console.error("Error processing result:", error);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.error("Error fetching data:", error);
      }
    }
  }
  async function processResult(reader, decoder, buffer) {
    try {
      const { done, value } = await reader.read();
      if (done) {
        controller = null;
        return;
      }
      if (value) {
        buffer += decoder.decode(value, { stream: true });
        let separatorIndex;
        while ((separatorIndex = buffer.indexOf("\n")) !== -1) {
          const jsonString = buffer.slice(0, separatorIndex);
          buffer = buffer.slice(separatorIndex + 1);
          const item = JSON.parse(jsonString);

          let birdSpeciesString;
          if (
            item.birdSpecies != "" &&
            item.birdSpecies != "Unknown" &&
            item.birdSpecies != "Identity unknown" &&
            item.birdSpecies != "Tap to enter bird species"
          ) {
            birdSpeciesString = "<br/> Bird Species: " + item.birdSpecies;
          } else {
            birdSpeciesString = "";
          }
          if (
            item.isProcessed == true ||
            item.isEdited == true ||
            item.isExtracted == true ||
            item.isTrimmed == true
          ) {
            filePath = "/processed_mp3s/";
            file_suffix = "_P";
          } else {
            filePath = "/processed_mp3s/";
            file_suffix = "_P";
          }
          const marker = L.marker([item.coord.lat, item.coord.lon]);
          var popup = L.popup();
          var contributeString = "";
          switch (item.source) {
            case "maccauley":
              contributeString =
                '<br></b>This recording is made available courtesy of the Macaulay Library and can be found in the archive <a href=" ' +
                item.sourceLink +
                '  " target="_blank">here</a>.<br></b>' +
                '<br/> <audio controls="" controlslist="nodownload" oncontextmenu="return false;"><source src="';
              break;
            case "xenoCanto":
              contributeString =
                '<br></b>This recording is made available courtesy of the Xeno Canto collection and can be found in the archive <a href=" ' +
                item.sourceLink +
                ' "target="_blank">here</a>.<br></b>' +
                '<br/> <audio controls="" controlslist="nodownload" oncontextmenu="return false;"><source src="';
              break;
            case "audioWeather":
              contributeString =
                "<br></b>This recording is a user-contributed submission made with the AudioWeather app.<br></b>" +
                '<br/> <audio controls="" controlslist="nodownload" oncontextmenu="return false;"><source src="';
              break;
          }
          popup.setContent(
            "<div oncontextmenu='return false;'>" +
              "<b>" +
              "Location: " +
              item.name +
              "<br/> Weather: " +
              item.weather[0].description +
              "<br/> Date: " +
              item.dateName +
              "<br/> Temperature: " +
              item.main.temp +
              "&deg; C" +
              "<br/> Humidity: " +
              item.main.humidity +
              " %" +
              "<br/> Pressure: " +
              item.main.pressure +
              " hPa" +
              "<br/> Wind speed: " +
              item.wind.speed +
              " km/h" +
              birdSpeciesString +
              "<br/> Unique ID: " +
              item._id +
              "<br/>" +
              contributeString +
              baseMediaPath +
              filePath +
              item.filename +
              file_suffix +
              '.mp3"> type="audio/mp3" </audio>' +
              "</div>"
          );

          marker.bindPopup(popup);

        markers.addLayer(marker);
        mymap.addLayer(markers);

        }
      }
      await processResult(reader, decoder, buffer);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.error(error);
      }
    }
  }
}

var noUiSliderTempRange = document.getElementById("noUiSliderTempRange");

noUiSlider.create(noUiSliderTempRange, {
  start: [0, 40],
  connect: true,
  tooltips: [true, true],
  range: {
    min: -20,
    max: 60,
  },
  format: wNumb({
    decimals: 2,
    suffix: " °C",
  }),
});

noUiSliderTempRange.noUiSlider.on("update", function (values, handles) {
  minTemp = values[0];
  maxTemp = values[1];
  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("minTemp", minTemp);
      localStorage.setItem("maxTemp", maxTemp);
    }
  }
});

var noUiSliderHumidityRange = document.getElementById(
  "noUiSliderHumidityRange"
);

noUiSlider.create(noUiSliderHumidityRange, {
  start: [5, 90],
  connect: true,
  tooltips: [true, true],
  range: {
    min: 0,
    max: 100,
  },
  format: wNumb({
    decimals: 2,
    suffix: " %",
  }),
});

noUiSliderHumidityRange.noUiSlider.on("update", function (values, handles) {
  minHumidity = values[0];
  maxHumidity = values[1];

  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("minHumidity", minHumidity);
      localStorage.setItem("maxHumidity", maxHumidity);
    }
  }
});

var noUiSliderWindSpeedRange = document.getElementById(
  "noUiSliderWindSpeedRange"
);

noUiSlider.create(noUiSliderWindSpeedRange, {
  start: [0, 15],
  connect: true,
  tooltips: [true, true],
  range: {
    min: 0,
    max: 40,
  },
  format: wNumb({
    decimals: 2,
    suffix: " Km/h",
  }),
});

noUiSliderWindSpeedRange.noUiSlider.on("update", function (values, handles) {
  minWindSpeed = values[0];
  maxWindSpeed = values[1];

  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("minWindSpeed", minWindSpeed);
      localStorage.setItem("maxWindSpeed", maxWindSpeed);
    }
  }
});

function timestamp(str) {
  return new Date(str).getTime();
}

var noUiSliderDateRange = document.getElementById("noUiSliderDateRange");

var dateValues = [
  document.getElementById("event-start"),
  document.getElementById("event-end"),
];
var startentry = 2;
var endEntry = 2;

startentry = timestamp("26 Feb 1970 00:12:00 GMT");
endEntry = timestamp(Date.now());

const dateDisplayFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

noUiSlider.create(noUiSliderDateRange, {
  start: [startentry, endEntry],
  connect: true,
  tooltips: [
    {
      to: function (value) {
        return dateDisplayFormatter.format(new Date(+value));
      },
    },
    {
      to: function (value) {
        return dateDisplayFormatter.format(new Date(+value));
      },
    },
  ],

  range: {
    min: startentry,
    max: endEntry,
  },
  format: wNumb({
    decimals: 0,
  }),
});

noUiSliderDateRange.noUiSlider.on("update", function (values, handle) {
  earliestDate = values[0];
  latestDate = values[1];
  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("earliestDate", earliestDate);
      localStorage.setItem("latestDate", latestDate);
    }
  }
});

var noUiSliderTimeRange = document.getElementById("noUiSliderTimeRange");

noUiSlider.create(noUiSliderTimeRange, {
  start: [0, 1440],
  connect: true,
  tooltips: {
    to: function (value) {
      var hours = Math.floor(value / 60);
      var minutes = (value % 60).toFixed(0);
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    },
  },
  range: {
    min: 0,
    max: 1440,
  },
});

noUiSliderTimeRange.noUiSlider.on("update", function (values, handles) {
  earliestTime = Math.round(values[0]);
  latestTime = Math.round(values[1]);
  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("earliestTime", earliestTime);
      localStorage.setItem("latestTime", latestTime);
    }
  }
});

var noUiSliderTimeOfYearRange = document.getElementById(
  "noUiSliderTimeOfYearRange"
);

noUiSlider.create(noUiSliderTimeOfYearRange, {
  start: [0, 364],
  connect: true,
  tooltips: {
    to: function (value) {
      var date = new Date(new Date().getFullYear(), 0);
      date.setDate(Math.round(value) + 1);
      var month = date.toLocaleString("default", { month: "short" });
      var day = date.getDate();
      return month + " " + day;
    },
  },
  range: {
    min: 0,
    max: 364,
  },
});

noUiSliderTimeOfYearRange.noUiSlider.on("update", function (values, handles) {
  earliestTimeOfYear = Math.round(
    noUiSliderTimeOfYearRange.noUiSlider.get()[0]
  );
  latestTimeOfYear = Math.round(noUiSliderTimeOfYearRange.noUiSlider.get()[1]);
  clearTimeout(timeoutId);
  if (useFilters) {
    timeoutId = setTimeout(getData, 500);
    if (useLocalStorage) {
      localStorage.setItem("earliestTimeOfYear", earliestTimeOfYear);
      localStorage.setItem("latestTimeOfYear", latestTimeOfYear);
    }
  }
});

var filterSwitch = document.getElementById("toggle_filters");
filterSwitch.addEventListener("click", function () {
  if (this.checked) {
    useFilters = true;
    if (useLocalStorage) {
      localStorage.setItem("useFilters", useFilters);
    }
    if (debugVerbose) {
      console.log("using filters");
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  } else {
    useFilters = false;
    if (useLocalStorage) {
      localStorage.setItem("useFilters", useFilters);
    }
    if (debugVerbose) {
      console.log("not using filters");
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  }
});

var xenoSwitch = document.getElementById("include_xeno_canto");
xenoSwitch.addEventListener("click", function () {
  if (this.checked) {
    includeXenoCanto = true;
    include_xeno_canto_string = "true";
    if (useLocalStorage) {
      localStorage.setItem("includeXenoCanto", includeXenoCanto);
    }
    if (debugVerbose) {
      console.log("using includeXenoCanto");
      console.log("XenoCantoString: " + include_xeno_canto_string);
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  } else {
    includeXenoCanto = false;
    include_xeno_canto_string = "false";
    if (useLocalStorage) {
      localStorage.setItem("includeXenoCanto", includeXenoCanto);
    }
    if (debugVerbose) {
      console.log("XenoCantoString: " + include_xeno_canto_string);
      console.log("not using includeXenoCanto");
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  }
});

var macauleySwitch = document.getElementById("include_macauley");
macauleySwitch.addEventListener("click", function () {
  if (this.checked) {
    includeMacauley = true;
    include_macauley_string = "true";
    if (useLocalStorage) {
      localStorage.setItem("includeMacauley", includeMacauley);
    }
    if (debugVerbose) {
      console.log("using includeMacauley");
      console.log("XenoCantoString: " + include_macauley_string);
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  } else {
    includeMacauley = false;
    include_macauley_string = "false";
    if (useLocalStorage) {
      localStorage.setItem("includeMacauley", includeMacauley);
    }
    if (debugVerbose) {
      console.log("includeMacauley: " + include_macauley_string);
      console.log("not using includeMacauley");
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(getData, 500);
  }
});

function writeToLocalStorage() {
  if (typeof Storage !== "undefined") {
    if (debugVerbose) {
      console.log("local storage available");
    }
    localStorage.setItem("useFilters", useFilters);
    localStorage.setItem("maxTemp", maxTemp);
    localStorage.setItem("minTemp", minTemp);
    localStorage.setItem("minHumidity", minHumidity);
    localStorage.setItem("maxHumidity", maxHumidity);
    localStorage.setItem("minWindSpeed", minWindSpeed);
    localStorage.setItem("maxWindSpeed", maxWindSpeed);
    localStorage.setItem("earliestDate", earliestDate);
    localStorage.setItem("latestDate", latestDate);
    localStorage.setItem("earliestTimeOfYear", earliestTimeOfYear);
    localStorage.setItem("latestTimeOfYear", latestTimeOfYear);
    localStorage.setItem("earliestTime", earliestTime);
    localStorage.setItem("latestTime", latestTime);
    localStorage.setItem("includeXenoCanto", includeXenoCanto);
    localStorage.setItem("includeXenoCanto", includeMacauley);
  }
}
function readFromlocalStorage() {
  if (typeof Storage !== "undefined") {
    if (debugVerbose) {
      console.log("local storage available");
    }
    useFilters = localStorage.getItem("useFilters");
    if (useFilters == "true") {
      filterSwitch.checked = true;
    } else {
      filterSwitch.checked = false;
    }
    if (debugVerbose) {
      console.log("Loading from local storage: useFilters: " + useFilters);
    }
    maxTemp = localStorage.getItem("maxTemp");
    if (debugVerbose) {
      console.log("Loading from local storage: maxTemp: " + maxTemp);
    }
    minTemp = localStorage.getItem("minTemp");
    if (debugVerbose) {
      console.log("Loading from local storage: minTemp: " + minTemp);
    }
    minHumidity = localStorage.getItem("minHumidity");
    if (debugVerbose) {
      console.log("Loading from local storage: minHumidity: " + minHumidity);
    }
    maxHumidity = localStorage.getItem("maxHumidity");
    if (debugVerbose) {
      console.log("Loading from local storage: maxHumidity: " + maxHumidity);
    }
    minWindSpeed = localStorage.getItem("minWindSpeed");
    if (debugVerbose) {
      console.log("Loading from local storage: minWindSpeed: " + minWindSpeed);
    }
    maxWindSpeed = localStorage.getItem("maxWindSpeed");
    if (debugVerbose) {
      console.log("Loading from local storage: maxWindSpeed: " + maxWindSpeed);
    }
    earliestDate = localStorage.getItem("earliestDate");
    if (debugVerbose) {
      console.log("Loading from local storage: earliestDate: " + earliestDate);
    }
    latestDate = localStorage.getItem("latestDate");
    if (debugVerbose) {
      console.log("Loading from local storage: latestDate: " + latestDate);
    }
    earliestTimeOfYear = localStorage.getItem("earliestTimeOfYear");
    if (debugVerbose) {
      console.log(
        "Loading from local storage: earliestTimeOfYear: " + earliestTimeOfYear
      );
    }
    latestTimeOfYear = localStorage.getItem("latestTimeOfYear");
    if (debugVerbose) {
      console.log(
        "Loading from local storage: latestTimeOfYear: " + latestTimeOfYear
      );
    }
    earliestTime = localStorage.getItem("earliestTime");
    if (debugVerbose) {
      console.log("Loading from local storage: earliestTime: " + earliestTime);
    }
    latestTime = localStorage.getItem("latestTime");
    if (debugVerbose) {
      console.log("Loading from local storage: latestTime: " + latestTime);
    }
    includeXenoCanto = localStorage.getItem("includeXenoCanto");
    if (debugVerbose) {
      console.log(
        "Loading from local storage: includeXenoCanto: " + includeXenoCanto
      );
    }
    includeMacauley = localStorage.getItem("includeMacauley");
    if (debugVerbose) {
      console.log(
        "Loading from local storage: includeMacauley: " + includeMacauley
      );
    }
  }
}

function splitStream(delimiter) {
  let buffer = "";

  return new TransformStream({
    transform(chunk, controller) {
      buffer += chunk;
      const parts = buffer.split(delimiter);
      buffer = parts.pop();

      for (const part of parts) {
        controller.enqueue(part);
      }
    },

    flush(controller) {
      if (buffer) {
        controller.enqueue(buffer);
      }
    },
  });
}
